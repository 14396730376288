*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans',sans-serif ;
}

html,
body, 
#root {
  background-color: #FFFFFF;
  height: 100%;
}