.success_cases_section {
  padding: 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.success_cases_section h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
  position: relative;
}

.success_cases_section p {
  margin-bottom: 2rem;
  font-size: 1.2em;
  color: #666;
}

.title_underline {
  width: 90%;
  max-width: 500px;
  height: 4px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto;
}

.case {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  text-align: left;
  gap: 2rem;
  position: relative; /* Para futuros ajustes de posicionamento */
}

.case_image {
  width: 45%;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.case_image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.case_text {
  width: 45%;
  position: relative;
}

.case_text h3 {
  font-size: 1.6em;
  color: #891F2A;
  margin-bottom: 0.5rem;
}

.case_text p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

.case_text a {
  display: inline-block;
  margin-top: 1rem;
  color: #891F2A;
  font-weight: bold;
  text-decoration: none;
}

.divider {
  width: 100%;
  height: 1px;
  background: #ddd;
  margin: 2rem 0;
}

/* Responsivo para Tablets */
@media (max-width: 1024px) {
  .success_cases_section {
    padding: 1.5rem;
  }

  .case {
    flex-direction: column;
    gap: 1.5rem;
  }

  .case_image,
  .case_text {
    width: 100%;
  }
}

/* Responsivo para Celulares */
@media (max-width: 767px) {
  .success_cases_section {
    padding: 1rem;
  }

  .success_cases_section h2 {
    font-size: 1.8em;
  }

  .success_cases_section p {
    font-size: 1.1em;
  }

  .case_image,
  .case_text {
    width: 100%;
  }

  .case_text h3 {
    font-size: 1.4em;
  }

  .case_text p {
    font-size: 1em;
  }

  .case_text a {
    margin-top: 0.5rem;
  }
}
