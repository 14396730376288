.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #891F2A;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
  z-index: 10;
}

.scrollToTop:hover {
  color: #891F2A;
  background-color: #fff;
  border: 2px solid #891F2A;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
}
