.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #ffffff;
  padding: 20px;
}

.errorCode {
  margin: 0 0 20px 0;
  font-size: 50px;
  color: #891F2A;
  background-color: #f1f1f1;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verticalLine {
  width: 1px;
  height: 150px;
  background-color: #891F2A;
  margin: 20px 0;
}

/* Tornar a linha horizontal em dispositivos móveis */
@media (max-width: 767px) {
  .verticalLine {
    width: 250px;
    height: 1px;
    margin: 20px 0;
  }
}

.errorMessage {
  text-align: center;
  max-width: 90%;
  font-family: Arial, sans-serif;
}

.errorCode h1 {
  font-size: 4rem;
  color: #891F2A;
}

.errorMessage h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.errorMessage p {
  font-size: 1rem;
  color: #555;
}

.backHomeButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #891F2A;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  border: 2px solid #891F2A;
}

.backHomeButton:hover {
  background-color: #fff;
  color: #891F2A;
}

/* Estilização para tamanhos maiores */
@media (min-width: 768px) {
  .errorContainer {
    flex-direction: row;
  }

  .errorCode {
    margin-right: 50px;
    width: 250px;
    height: 250px;
  }

  .verticalLine {
    height: 200px;
  }

  .errorMessage {
    text-align: left;
    margin-left: 50px;
    max-width: 500px;
  }

  .errorMessage h2 {
    font-size: 1.5rem;
  }

  .errorMessage p {
    font-size: 1rem;
  }
}
