.home_container {
  width: 100%;
  max-width: 1400px; /* Limita o tamanho máximo da seção inicial */
  margin: 0 auto; /* Centraliza o conteúdo */
  display: flex;
  flex-direction: row;
  padding: 4em;
  margin-bottom: 0.5em;
  position: relative;
}

.home_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 3%;
  right: 3%;
  width: 94%;
  border-bottom: 3px solid #891F2A;
}

.text_content {
  flex-direction: column;
}

.text_content h1 {
  font-size: 35px;
  margin-bottom: 0.5em;
  text-align: left;
  font-weight: normal;
}

.text_content span {
  padding: 0 0.2em;
  border-bottom: 3px solid #891F2A;
  display: inline-block;
}

.text_content h3 {
  font-size: 35px;
  text-align: left;
}

.text_content p {
  font-size: 20px;
  margin-bottom: 1.5em;
  color: #000;
}

.home_container img {
  margin-left: auto;
  width: 350px;
  height: auto;
  margin: 1em 0;
}

.new_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em;
  margin-bottom: 5em;
}

.text_pag2 {
  margin-bottom: 0.5em;
  text-align: center;
}

.text_pag2 h1 {
  font-size: 40px;
}

.text_pag2 h3 {
  color: #891F2A;
  font-size: 20px;
  margin-bottom: 0.5em;
  text-align: center;
}

.new_container {
  width: 100%;
  padding: 4em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
  max-width: 1200px; /* Limita o tamanho máximo do slider */
  margin: 0 auto;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.cardWrapper {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.cardWrapper:hover {
  transform: scale(1.05);
}

.cardWrapper img {
  width: 80px; /* Ajusta o tamanho das imagens */
  height: auto;
  margin-bottom: 1rem;
  filter: grayscale(100%);
  transition: filter 0.3s ease, transform 0.3s ease;
}

.cardWrapper:hover img {
  filter: grayscale(0%);
  transform: scale(1.05);
}

.cardWrapper h3 {
  font-size: 1.2rem;
  color: #fff;
  background-color: #891F2A;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin: 0;
}

.cardWrapper p {
  font-size: 0.8rem;
  color: #fff;
  text-align: center;
  line-height: 1.15rem;
  margin: 0;
}

.stats_section {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4em 1em;
  max-width: 1400px; /* Limita o tamanho máximo da seção */
  margin: 0 auto; /* Centraliza o conteúdo */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(137, 31, 42, 0.7);
  z-index: 1;
}

.stats_container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  gap: 20px;
}

.stat_card {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  min-width: 150px;
  flex: 1 1 calc(33.333% - 20px);
}

.stat_card h2 {
  margin: 0;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
}

.stat_card p {
  margin: 0;
  color: #fff;
  font-size: 1em;
}

/* Responsividade */
@media (max-width: 1024px) {
  .home_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2em;
  }

  .home_container img {
    order: -1;
    width: 100%;
    max-width: none;
    width: 300px;
  }

  .text_content h1, .text_content h3, .text_content p {
    text-align: center;
    margin: 0.5em 0;
  }

  .button_container {
    margin-top: 20px;
  }

  .slider {
    max-width: 900px;
  }

  .cardWrapper {
    padding: 15px;
  }

  .cardWrapper img {
    width: 70px;
  }

  .cardWrapper h3 {
    font-size: 1.1rem;
  }

  .cardWrapper p {
    font-size: 0.8rem;
  }

  .stat_card {
    flex: 1 1 100%;
  }

  .case, .about_content, .testimonial {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .case_image, .about_image {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .home_container img {
    width: 200px;
  }

  .new_container h1 {
    font-size: 1.5em;
  }

  .new_container h3 {
    font-size: 1em;
  }

  .text_content h1 {
    text-align: center;
    font-size: 25px;
    margin: 0.5em 0;
  }

  .text_content h3, .text_content p {
    text-align: center;
    font-size: 15px;
    margin: 0.5em 0;
  }

  .button_container {
    margin-top: 1em;
  }

  .slider {
    max-width: 600px;
  }

  .cardWrapper {
    padding: 10px;
  }

  .cardWrapper img {
    width: 60px;
  }

  .cardWrapper h3 {
    font-size: 1rem;
  }

  .cardWrapper p {
    font-size: 0.75rem;
  }

  .stat_card {
    flex: 1 1 100%;
  }

  .case, .about_content, .testimonial {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .case_image, .about_image {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .slider {
    max-width: 260px;
  }

  .cardWrapper {
    padding-inline: 15px;
  }

  .cardWrapper img {
    width: 50px;
  }

  .cardWrapper h3 {
    font-size: 0.9rem;
  }

  .cardWrapper p {
    font-size: 0.7rem;
  }
}