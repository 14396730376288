/* Removendo completamente as setas padrão do slick-carousel */
.slick-prev,
.slick-next {
  display: none !important;  /* Remove as setas padrão */
}

.slick-prev:before, 
.slick-next:before {
  content: none !important;  /* Remove qualquer conteúdo das setas padrão */
}

/* Estilo das setas personalizadas */
.nextArrow,
.prevArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent; /* Sem fundo */
  color: #891F2A;  /* Seta vermelha */
  cursor: pointer;
  z-index: 3;  /* Garante que as setas estejam à frente de outros elementos */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Efeito de hover nas setas */
.nextArrow:hover,
.prevArrow:hover {
  color: #b32d40; /* Cor ao passar o mouse, um tom mais claro de vermelho */
}

/* Seta direita (avançar) */
.nextArrow i {
  font-size: 24px;  /* Tamanho da seta */
}

/* Seta esquerda (voltar) */
.prevArrow i {
  font-size: 24px;  /* Tamanho da seta */
}

/* Garantir que o slick-carousel não injete setas padrão */
.slick-prev:before,
.slick-next:before {
  display: none !important;  /* Remove qualquer resquício de ícones padrão */
}

/* Ajuste de responsividade para setas em dispositivos menores */
@media (max-width: 768px) {
  .nextArrow,
  .prevArrow {
    width: 30px;
    height: 30px;
  }

  .nextArrow i,
  .prevArrow i {
    font-size: 20px; /* Reduzir o tamanho das setas em dispositivos menores */
  }
}

/* Ajuste de responsividade para dispositivos muito pequenos */
@media (max-width: 480px) {
  .nextArrow,
  .prevArrow {
    width: 25px;
    height: 25px;
  }

  .nextArrow i,
  .prevArrow i {
    font-size: 18px; /* Tamanho ainda menor para telas pequenas */
  }
}
