/* Estilo base da seção MVV */
.Mvv_section {
  padding: 2em 1em;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;  /* Ajustado para não exceder a tela */
  overflow: hidden;
}

.Mvv_section h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.title_underline {
  width: 500px;
  max-width: 90%;  /* Ajustado para telas menores */
  height: 2px;
  background-color: #891F2A;
  margin: 0 auto 0.5em auto;
}

.Mvv_section p {
  margin-bottom: 2em;
  font-size: 1.5em;
  color: #000;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  /* Permitir que os itens fiquem em várias linhas */
  width: 100%;
  margin-bottom: 20px;
}

.Mvv {
  background-color: #f9f9f9;
  border: 1px solid #891F2A;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 440px;
  margin: 0 20px 20px 20px;  /* Ajustado para evitar excesso de margem */
}

.Mvv h3 {
  font-size: 1.6em;
  color: #891F2A;
}

.Mvv h4 {
  font-size: 1em;
  color: #555;
}

.Mvv p {
  font-size: 1em;
  color: #555;
}

/* Seção Valores */
.cards_section {
  padding: 2em 1em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;  /* Ajustado */
  margin: 0 auto;
}

.title_card {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.title_card_underline {
  width: 80%;
  max-width: 400px;
  height: 2px;
  background-color: #891F2A;
  margin: 0 auto 0.5em auto;
}

/* Estilo do slider */
.slider {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;  /* Evitar que o slider ultrapasse os limites */
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 0 15px;
}

.cardWrapper {
  width: 100%;
  max-width: 235px;
  padding: 12px;
  background-color: #fff;
  border-radius: 15px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.cardWrapper:hover {
  transform: scale(1.05);
}

/* Responsividade para telas pequenas */
@media (max-width: 599px) {
  .Mvv_section {
    padding: 1.5em 1em;
  }
  
  .Mvv_section h2 {
    font-size: 1.8em;
  }

  .title_underline {
    width: 80%;
    margin-bottom: 1em;
  }

  .row {
    flex-direction: column;
    gap: 20px;
  }

  .Mvv {
    max-width: 100%;
    padding: 15px;
    margin: 0 0 20px 0;
  }

  .cards_section {
    padding: 1.5em 1em;
  }

  .slider {
    max-width: 300px;
  }

  .cardWrapper {
    max-width: 270px;
    padding: 20px;
  }
}

/* Responsividade para tablets */
@media (min-width: 600px) and (max-width: 1024px) {
  .slider {
    max-width: 750px;
  }

  .cardWrapper {
    max-width: 270px;
  }
}
