/* Estilos globais */
.success_services_section {
  padding: 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.success_services_section h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.success_services_section h2 {
  font-size: 1.8em;
  color: #444;
  margin-bottom: 1.5rem;
}

.success_services_section p {
  margin-bottom: 2rem;
  font-size: 1.2em;
  color: #666;
}

.title_underline {
  width: 90%;
  max-width: 500px;
  height: 4px;
  background-color: #891F2A;
  margin: 0.5em auto 1.5em auto;
}

.service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  text-align: left;
  gap: 2rem;
}

.service:nth-of-type(odd) {
  flex-direction: row-reverse;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.service_image {
  width: 45%;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service_image:hover {
  transform: scale(1.05);
}

.service_text {
  width: 45%;
}

.service_text h3 {
  font-size: 1.6em;
  color: #891F2A;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.service_text p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

.divider {
  width: 100%;
  height: 1px;
  background: #ddd;
  margin: 2rem 0;
}

/* Responsivo para Tablets */
@media (max-width: 1024px) {
  .success_services_section {
    padding: 1.5rem;
  }

  .service {
    flex-direction: column;
    gap: 1.5rem;
  }

  .service_image,
  .service_text {
    width: 100%;
  }
}

/* Responsivo para Celulares */
@media (max-width: 767px) {
  .success_services_section {
    padding: 1rem;
  }

  .success_services_section h1 {
    font-size: 2em;
  }

  .success_services_section h2 {
    font-size: 1.5em;
  }

  .success_services_section p {
    font-size: 1.1em;
  }

  .service_image,
  .service_text {
    width: 100%;
  }

  .service_text h3 {
    font-size: 1.4em;
  }

  .service_text p {
    font-size: 1em;
  }
}
