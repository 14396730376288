.btn {
  background-color: #891F2A;
  border: 2px solid #891F2A;
  color: #fff;
  padding: 0.5em 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: 0.5s;
  border-radius: 1.5px;
  margin-right: 10px;
  cursor: pointer; 
}

.btnSecond {
  background-color: #fff;
  border: 2px solid #891F2A;
  color: #891F2A;
  padding: 0.5em 1em;
  font-weight: bolder;
  text-decoration: none;
  transition: 0.5s;
  border-radius: 1.5px;
  margin-right: 10px;
}

.btn:hover {
  color: #891F2A;
  background-color: #fff;
  border: 2px solid #891F2A;
}

.btnSecond:hover {
  color: #fff;
  background-color: #891F2A;
  border: 2px solid #891F2A;
}

@media (max-width: 768px) {

.btn {
  background-color: #891F2A;
  border: 2px solid #891F2A;
  color: #fff;
  padding: 0.8em 1.5em; /* Aumentado o padding para melhor usabilidade em dispositivos móveis */
  font-weight: bolder;
  text-decoration: none;
  transition: 0.3s; /* Reduzido o tempo de transição para uma resposta mais rápida */
  border-radius: 4px; /* Aumentado o border-radius para um estilo mais suave */
  margin: 0.5em 0; /* Margin ajustado para melhor espaçamento vertical em mobile */
  display: inline-block; /* Adicionado para garantir que o botão respeite o padding */
  margin-right: 5px;
}

.btnSecond {
  background-color: #fff;
  border: 2px solid #891F2A;
  color: #891F2A;
  padding: 0.8em 1.5em; /* Aumentado o padding para melhor usabilidade em dispositivos móveis */
  font-weight: bolder;
  text-decoration: none;
  transition: 0.3s; /* Reduzido o tempo de transição para uma resposta mais rápida */
  border-radius: 4px; /* Aumentado o border-radius para um estilo mais suave */
  margin: 0.5em 0; /* Margin ajustado para melhor espaçamento vertical em mobile */
  display: inline-block; /* Adicionado para garantir que o botão respeite o padding */
}
}