.footer {
  background-color: #891F2A; /* Cor sólida para o fundo */
  color: #ffffff;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2em;
  max-width: 1200px;
  margin: 0 auto;
}

.leftContent, .centerContent, .rightContent {
  flex: 1;
}

.leftContent {
  text-align: left;
}

.leftContent h3 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 0.5em;
}

.leftContent h3 span {
  color: #fff; /* Destaque em dourado */
}

.contactButton {
  background-color: #ffffff; /* Cor de fundo branca */
  color: #891F2A; /* Texto escuro */
  padding: 0.8em 2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contactButton:hover {
  background-color: #fff; /* Cor dourada no hover */
  color: #891F2A;
}

.centerContent {
  text-align: center;
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
}

.socialList {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  gap: 1em;
}

.socialList li {
  margin: 0.5em 0em;
}

.socialList a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.socialList a:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.3);
}

.socialList svg {
  font-size: 1.5em;
}

.rightContent {
  text-align: right;
}

.location {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1em;
}

.location p {
  font-size: 0.9em;
  color: #ffffff;
  line-height: 1.5;
  text-align: right;
}

.locationIcon {
  font-size: 1.8rem;
  margin-bottom: 0.5em;
  color: #fff; /* Ícone em dourado */
}

.copyRight {
  margin-top: 1.5em;
  font-size: 0.8rem;
  color: #ddd;
  transition: color 0.3s ease;
}

/* Responsivo para Tablets (768px - 1024px) */
@media (max-width: 1024px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .leftContent,
  .centerContent,
  .rightContent {
    text-align: center;
    margin: 0em 3em;
  }

  .logo {
    font-size: 2rem;
  }

  .location {
    align-items: center;
    text-align: center;
  }

  .location p {
    text-align: center;
  }
}

/* Responsivo para Celulares (320px - 767px) */
@media (max-width: 767px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .contactButton,
  .backToTop {
    width: 100%;
    max-width: 280px;
  }

  .socialList {
    justify-content: center;
  }

  .location {
    align-items: center;
    text-align: center;
  }

  .location p {
    text-align: center;
  }

  .logo {
    font-size: 1.5rem;
  }

  .locationIcon {
    font-size: 1.5rem;
  }

  .copyRight {
    font-size: 0.8rem;
  }
}
