/* Container principal */
.container {
  width: 100%;
  max-width: 1400px; /* Limita a largura máxima */
  margin: 0 auto;
  padding-top: 2em;
  font-family: 'Arial', sans-serif;
}

/* Estilos do cabeçalho de contato */
.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Permite a quebra de linha em telas menores */
  margin-bottom: 2em;
}

.contactHeader div {
  flex: 1;
  max-width: 400px;
  margin-bottom: 1em;
}

.contactHeader h1 {
  font-size: 2.3em;
  margin-bottom: 0.5em;
  color: #000;
}

.contactHeader h1 span {
  color: #891F2A;
}

.contactHeader p {
  font-size: 1.1em;
  color: #333;
}

/* Estilos do formulário */
.budgetForm {
  max-width: 600px;
  width: 100%;
  padding: 1.5em;
  background-color: #fff;
  border-radius: 10px;
  margin: 1em auto;
  transition: transform 0.3s, box-shadow 0.3s;
}

.budgetForm:hover {
  transform: scale(1.02);
}

.budgetForm label {
  display: block;
  font-weight: bold;
  color: #891F2A;
  margin-bottom: 0.3em;
  font-size: 0.9em;
  text-align: left;
}

.budgetForm input,
.budgetForm textarea {
  width: 100%;
  padding: 0.5em 0; /* Apenas padding vertical */
  margin-bottom: 1em;
  border: none; /* Remove todas as bordas */
  border-bottom: 2px solid #891F2A; /* Risco vermelho apenas na parte inferior */
  outline: none;
  font-size: 0.9em;
  background-color: transparent;
  transition: border-color 0.3s ease;
}

.budgetForm input::placeholder,
.budgetForm textarea::placeholder {
  color: #aaa; /* Cor do placeholder */
}

.budgetForm input:focus,
.budgetForm textarea:focus {
  border-bottom: 2px solid #700f1a; /* Cor de foco */
}

.submitButton {
  width: 100%;
  background-color: #891F2A;
  color: #fff;
  padding: 0.75em;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
}

.submitButton:hover {
  background-color: #700f1a;
  transform: scale(1.05);
}

/* Detalhes do orçamento */
.budgetDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  background: url('../../assets/fundo_reuniao.jpeg') no-repeat center center;
  background-size: cover;
  padding: 2em;
  border-radius: 10px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(137, 31, 42, 0.7);
  z-index: 0;
}

.detailsLeft,
.detailsRight {
  z-index: 2;
  max-width: 600px;
  width: 100%;
}

.detailsLeft {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.detailsRight {
  margin-top: 2em;
  text-align: center;
}

.personInfo {
  display: flex;
  align-items: center;
}

.personInfo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1em;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.personInfo h3 {
  font-size: 1.5em;
  margin: 0;
  color: #fff;
}

.personInfo p {
  margin: 0.2em 0;
  color: #fff;
}

.hours {
  display: flex;
  align-items: center;
  text-align: left;
}

.hours img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1em;
}

.hours h3 {
  font-size: 1.5em;
  color: #fff;
}

.hours p {
  margin: 0.2em 0;
  color: #fff;
}

/* Localização */
.location {
  text-align: center;
  color: #fff;
}

.location h3 {
  margin-bottom: 0.5em;
  color: #fff;
}

.location p {
  margin-bottom: 1em;
  color: #fff;
}

.location iframe {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: none;
}

.separator {
  margin: 2em 0;
  width: 80%;
  height: 2px;
  background-color: #fff;
  opacity: 0.5;
}

/* Ajustes para telas maiores */
@media (min-width: 1400px) {
  .location iframe {
    height: 400px; /* Aumenta a altura do mapa */
  }

  .contactHeader div {
    max-width: 600px; /* Aumenta o espaço do texto */
    margin-left: 50px; /* Move o texto mais para a direita */
  }
}

@media (min-width: 1200px) {
  .location iframe {
    height: 400px; /* Aumenta a altura do mapa */
  }

  .contactHeader div {
    max-width: 500px; /* Aumenta o espaço do texto */
    margin-left: 50px; /* Move o texto mais para a direita */
  }
}

/* Animações */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsividade para Tablets (768px - 1024px) */
@media (max-width: 1024px) {
  .contactHeader {
    flex-direction: column;
    text-align: center;
  }

  .budgetForm {
    max-width: 90%;
  }

  .detailsLeft {
    flex-direction: column;
    align-items: center;
  }

  .personInfo {
    margin-bottom: 1em;
  }

  .hours {
    margin-top: 1em;
    justify-content: center;
    text-align: center;
  }
}

/* Responsividade para Celulares (320px - 767px) */
@media (max-width: 767px) {
  .contactHeader {
    flex-direction: column;
    padding: 0;
    text-align: center;
  }

  .budgetForm {
    max-width: 100%;
    padding: 1em;
    margin-right: 0; /* Remove o espaço à direita no mobile */
  }

  .budgetForm label {
    text-align: left; /* Garante que os labels estejam à esquerda no mobile */
  }

  .detailsLeft {
    flex-direction: column;
    align-items: center;
  }

  .personInfo {
    margin-bottom: 1em;
    text-align: center;
  }

  .hours {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .location iframe {
    height: 250px;
  }

  .detailsRight {
    text-align: center;
  }
}
