.carouselContainer {
  text-align: center;
  margin: 0 auto;
  padding: 0 2em; /* Adicionei padding para espaço das setas */
  position: relative; /* Para que as setas fiquem relativas ao container */
}

.slider {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.member {
  padding: 20px;
}

.memberCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.memberCard:hover {
  transform: scale(1.05);
}

.memberImageContainer {
  position: relative;
  margin-bottom: 15px;
}

.memberImage {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  background-color: #f0f0f0;
}

.memberImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.memberInfo {
  margin-top: 10px;
}

.memberName {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.memberRole {
  font-size: 1em;
  color: #777;
  margin-bottom: 15px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 10px;
}
